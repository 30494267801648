<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Add devices
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <div class="material-card-content">
      <p>
        To add a device to Boardgent, you must install the Boardgent agent on it.
        The agent is a program that keeps the computers securely connected to Boardgent.
      </p>
    </div>
    <b-tabs
      slot="external-footer"
      class="mt-4 tabs-container"
      :animated="false"
    >
      <b-tab-item
        v-if="$isBgCloud"
        label="Install in your computer"
      >
        <TabInfo
          class="material-card material-card-content"
          to-download
          id-info="agentSelfInstall"
          to-center
          @action="downloadAgentInThisComputer"
        >
          <template #description>
            <p>
              To install an agent in your computer, download it with the button below.
            </p>
            <p
              class="is-size-7 has-text-grey"
              style="margin-top: 5px"
            >
              Keep in mind that in order to succesfully install the agent,
              the installer must be run with administrative privileges.
            </p>
          </template>
        </TabInfo>
      </b-tab-item>
      <b-tab-item
        label="Deploy to other computers"
      >
        <div v-if="$isBgCloud">
          <TabInfo
            class="material-card"
            id-info="agentURL"
            @action="copyToClipboard('URL', token ?
              `${agentServer}?selfinstall=${token.token}` : '', 'Easy Install')"
          >
            <span
              slot="title"
              class="subtitle"
            >
              Send self-install URL
            </span>
            <template #description>
              You can send this URL to the users in  {{ company.name }}.
              When they visit the URL, it will generate a specific installer for their computers.
              After they install it, computers will appear in Boardgent.
            </template>
          </TabInfo>
        </div>
        <div class="material-card mt-5 p-2">
          <div style="margin-left:8px">
            <h1
              class="subtitle"
              style="margin-bottom:15px;"
            >
              Manually deploy
            </h1>
            <p style="margin-bottom:40px">
              You can manually deploy the Boardgent Agent in the following operating systems
              manually or using any third-party tool.
              <a
                href="https://help.boardgent.com/articles/2827019-install-the-vtul-agent"
                target="_blank"
              >Learn how to manually deploy the Boardgent Agent</a>.
            </p>
          </div>
          <div class="os-container columns is-desktop is-gapless">
            <div
              class="column has-text-centered"
              style="margin-bottom:30px"
            >
              <div>
                <b-icon
                  icon="microsoft-windows"
                  size="is-large"
                  style="margin-bottom:0px"
                />
              </div>
              <button
                id="win32Download"
                class="button is-outlined is-primary is-rounded downloadButton is-size-7-mobile"
                @click="downloadAgent('win32', 'x86')"
              >
                Download agent for Windows 32 Bits
              </button>
              <button
                id="win64Download"
                class="button is-outlined is-primary is-rounded downloadButton is-size-7-mobile"
                @click="downloadAgent('win32', 'x64')"
              >
                Download agent for Windows 64 Bits
              </button>
            </div>
            <div
              class=" column has-text-centered"
              style="margin-bottom:30px"
            >
              <div>
                <b-icon
                  icon="apple"
                  size="is-large"
                  style="margin-bottom:0px"
                />
              </div>
              <button
                id="mac64Download"
                class="button is-outlined is-primary is-rounded downloadButton is-size-7-mobile"
                @click="downloadAgent('darwin', 'x64')"
              >
                Download agent for Mac OS 64 Bits
              </button>
            </div>
            <div class="column has-text-centered">
              <div>
                <b-icon
                  icon="ubuntu"
                  size="is-large"
                  style="margin-bottom:0px"
                />
              </div>
              <button
                id="linux32Download"
                class="button is-outlined is-primary is-rounded downloadButton is-size-7-mobile"
                @click="downloadAgent('linux', 'x86')"
              >
                Download agent for Linux 32 Bits
              </button>
              <button
                id="linux64Download"
                class="button is-outlined is-primary is-rounded downloadButton is-size-7-mobile"
                @click="downloadAgent('linux', 'x64')"
              >
                Download agent for Linux 64 Bits
              </button>
            </div>
          </div>
          <div
            class="command-container"
          >
            <span
              v-if="token !== undefined"
              class="filter-style material-card-content is-size-7-mobile is-rounded"
              style="padding:9px 10px;border-radius: 5px; max-width: 100%"
            >
              {{ command }}
            </span>
            <a
              class="button is-small is-primary is-outlined "
              @click="copyToClipboard('command', command ? command : '', 'Installation Command')"
            >
              <b-icon
                icon="clipboard-outline"
              />
              <span>Copy command</span>
            </a>
          </div>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { paramsForServer } from 'feathers-hooks-common';

import TabInfo from '@/components/dashboard/DisplayDownloadAgentTabInfo.vue';

import toastMessage from '@/helpers/toastMessage';
import EnvironmentSetter from '@/helpers/environmentSetter';
import crossHelper from '@/helpers/cross';
import analyticsMixin from '@/mixins/analytics';

export default {
  name: 'DownloadagentServer',
  components: {
    TabInfo,
  },
  mixins: [analyticsMixin],
  metaInfo: {
    title: 'Add devices',
  },
  props: {
    company: {
      type: Object,
      default() {
        return {};
      },
    },
    agent: {
      type: Number,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      agentServer: EnvironmentSetter.getEnvironmentByIndex('VUE_APP_AGENT'),
      apiUrl: EnvironmentSetter.getEnvironmentByIndex('VUE_APP_API'),
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('companies-tokens', { findCompanyTokenInStore: 'find' }),
    command() {
      return `install ${this.token.token}${this.$isBgCloud ? '' : ` -s ${this.apiUrl}`}`;
    },
    token() {
      const tokens = this.findCompanyTokenInStore({
        query: this.getTokenQuery(),
      });
      if (tokens && tokens.data) {
        return tokens.data[0];
      }
      return null;
    },
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  created() {
    this.findCompanyToken(paramsForServer({
      skipRateLimit: true,
      query: this.getTokenQuery(),
    }));
  },
  methods: {
    ...mapActions('companies-tokens', { findCompanyToken: 'find' }),
    downloadAgentInThisComputer() {
      this.trackEvent(!this.currentCompany.isTestData && !this.user.isTestData,
        'track', 'Download Agent Current Device');
      if (!this.token) {
        toastMessage.showError('Error getting the current company token.');
        return;
      }
      crossHelper.openExternalLink(`${this.agentServer}?selfinstall=${this.token.token}`, false);
    },
    downloadAgent(platform, arch) {
      this.trackEvent(!this.currentCompany.isTestData && !this.user.isTestData,
        'track', 'Download Agent', {
          platform,
          arch,
        });
      crossHelper.openExternalLink(`${this.agentServer}?channel=latest&platform=${platform}&arch=${arch}&download=true`, false);
    },
    copyToClipboard(text, message, eventName) {
      this.trackEvent(!this.currentCompany.isTestData && !this.user.isTestData,
        'track', `Copy ${eventName}`);
      this.$copyText(message).then(() => {
        toastMessage.showSuccess(`Installation ${text} copied to clipboard`);
      }, () => {
        toastMessage.showError(`Cannot copy the ${text} to clipboard`);
      });
    },
    getTokenQuery() {
      return {
        companyId: this.company ? this.company.id : null,
        $limit: 1,
        enabled: true,
      };
    },
  },
};
</script>

<style scoped>
.downloadButton {
  margin: 10px;
}
.filter-style {
  background-color: #e8e8e8;
  font-size: 13px;
  padding: 4px 12px;
  word-wrap: break-word;
}
.command-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0;
  flex-wrap: wrap;
  gap: 30px 6px;
}
.os-container {
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}
</style>

<style>
.tabs-container nav{
  padding: 0 16px;
}
</style>
