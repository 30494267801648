<template>
  <div class="column is-centered">
    <div :class="{'centerContent':toCenter}">
      <slot name="title" />
      <b-icon
        v-if="toDownload"
        icon="monitor"
        size="is-large"
      />
      <p style="margin-top: 15px;">
        <slot name="description" />
      </p>
    </div>
    <div class="centerAll">
      <button
        v-if="toDownload"
        :id="idInfo"
        class="button is-outlined is-primary is-rounded is-size-7-mobile"
        style="min-width: 180px; margin-top: 35px"
        @click="$emit('action')"
      >
        <span class="icon is-small">
          <b-icon icon="download" />
        </span>
        <span>Download</span>
      </button>
      <span
        v-else
        :id="idInfo"
        class="button is-outlined is-primary is-rounded is-size-7-mobile"
        style="min-width: 180px; margin-top: 35px"
        @click="$emit('action')"
      >
        <span class="icon is-small">
          <b-icon icon="clipboard-outline" />
        </span>
        <span>Copy URL</span>
      </span>
    </div>
    <slot name="extraInfo" />
  </div>
</template>

<script>
export default {
  name: 'DisplayInfoTab',
  props: {
    idInfo: { type: String, required: true },
    toDownload: { type: Boolean },
    toCenter: { type: Boolean },
  },
};
</script>

<style scoped>
.centerContent {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
