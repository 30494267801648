<template>
  <div>
    <download-vtul-agent
      :company="currentCompany"
      :agent="1"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import downloadVtulAgent from '@/components/dashboard/DownloadVtulAgent.vue';

export default {
  name: 'AddDevice',
  components: {
    downloadVtulAgent,
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
  },
};
</script>
